.exercise-definition-row-container {
    text-align: left;
    border-bottom: 1px solid rgb(201, 201, 201);
    padding: 5px 0px;
}

.exercise-definition-name {
    font-weight: bolder;
    font-size: 15px;
}

.exercise-definition-desc {
    display: inline-block;
    font-size: 10px;
    padding: 0px 5px;
}