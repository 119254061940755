.architecture-text-container {
    display: inline-block;
    text-align: left;
    padding-left: 161px;
    padding-right: 200px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.me-info {
    display: inline-block;
    padding: 10px;
}

.icon{
    padding: 5px;
    height: 40px;
    width: 40px;
}

.me {
    border-radius: 200px;
    float: left;
    width: 150px;
}

.architecture-header-text {
    margin-top: 5px;
    margin-bottom: 5px;
}

.me-container {
    display: inline-block;
}

.my-name-text {
    font-size: 25px;
    font-weight: bold;
}

.architecture-stat-label {
    font-size: 15px;
    font-weight: bold;
}

.architecture-text {
    padding-top: 5px;
}

.architecture-diagram {
    width: 1300px;
    height: auto;
}