.App {
  text-align: center;
  background: linear-gradient(to bottom, rgb(207, 221, 231) 0%, rgb(207, 221, 231) 100%);
  min-height: 740px;
  min-width: 800px;
}

button {
  font-size: 15px;
  margin-left: 10px;
  margin-right: 10px;
  text-decoration: none;
  padding: 10px;
  border-radius: 20px;
}

button:focus {
  outline: none;
}

input:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

.primary-button {
  background-color: #152028;
  color: white;
  border: 1px solid #152028;
  display: inline-flex;
  align-items: center;
}

.primary-button:hover {
  background-color: white;
  color: #152028;
  border: 1px solid white;
}

.secondary-button {
  background-color: #828B92;
  color: white;
  border: 1px solid #828B92;
  display: inline-flex;
  align-items: center;
}

.secondary-button:hover {
  background-color: white;
  color: #828B92;
  border: 1px solid white;
}

.text-input {
  padding: 10px;
  margin: 10px 10px 0px 10px;
  outline: none;
  width: 200px;
  background-color: white;
  border-radius: 4px;
  border: none;
}

.text-input:focus {
  outline: none;
}

.icon-button {
  border: none;
}
