.workout-card {
    display: inline-block;
    border: 1px solid black;
    padding: 10px 20px;
    margin: 10px 20px;
}

.workout-definition-table-container {
    padding-top: 10px;
}

.workout-definition-table-card-container {
    display: inline-block;
}

.workout-definition-metrics-history {

}

.card {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    background-color: white;
    box-shadow: 4px 3px #c1b6b6;
}

.workout-def-metrics-table-container {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}

.table-item {
    display: inline-block;
    text-align: center;
    border: 1px solid black;
    width: 195px;
    height: 30px;
}