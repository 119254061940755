.workout-definition-card-container {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    vertical-align: top;
    min-width: 200px;
    background-color: white;
    box-shadow: 4px 3px #c1b6b6;
}

.workout-definition-card-container:hover {
    cursor: pointer;
}

.workout-definition-card-definition-name {
    font-weight: bolder;
    font-size: 25px;
}

.workout-avg-duration-container {
    font-size: 10px;
}

.exercise-definition-row-container {
    text-align: left;
    border-bottom: 1px solid rgb(201, 201, 201);
    padding: 5px 0px;
}

.exercise-definition-name {
    font-weight: bolder;
    font-size: 15px;
}

.exercise-definition-desc {
    display: inline-block;
    font-size: 10px;
    padding: 0px 5px;
}

