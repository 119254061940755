.login-window {
    background-color: #47555E;
    display: inline-block;
    border-radius: 20px;
    min-width: 300px;
    padding: 25px;
    margin-top: 10%;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.72);
}

.login-input-container {
    padding: 10px;
    color: white;
    font-size: 20px;
}

.login-logo {
    padding: 5px;
    height: 150px;
    width: auto;
    display: inline-block;
}

.login-input {
    padding: 5px;
    font-size: 15px;
    display: block;
    width: 100%;
    border-radius: 5px;
    border: none;
}

.input-label {
    text-align: left;
    padding-bottom: 5px;
}

.login-error-container {
    background-color: rgb(252, 214, 214);
    color: red;
    display: flex;
    align-items: center;
    margin-left: 10px;
    padding: 5px;
    border-radius: 5px;
}

.login-error-icon {
    padding-right: 5px;
}