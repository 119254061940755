.toolbar {
    text-align: left;
    background-color: #47555E;
    box-shadow: 0px 3px rgba(171, 183, 192, 0.815); 
}

.tool-bar-links-container {
    display: inline-flex;
    align-items: center;
}

.toolbar-button-container {
    float:right;
    margin-top: 12px;
    margin-right: 5px;
}

.toolbar-logo {
    padding: 10px;
    height: 50px;
    width: auto;
    display: inline-block;
}