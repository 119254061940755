.exercise-definition-search-container {
    display: inline-block;
    float: left;
    min-height: 666px;
    background-color: #152028;
    min-width: 20%;
    max-height: 666px;
}

.exercise-search-header-label {
    font-size: 25px;
    font-weight: bold;
    text-align: left;
    color: white;
    margin-left: 10px;
    margin-top: 10px;
}

.exercise-definition-search-input {
    padding: 10px;
    margin: 10px 10px 0px 10px;
    outline: none;
    width: 200px;
    display: block;
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    border: none;
}

.exercise-definition-search-results-container {
    background-color: white;
    text-align: left;
    margin-top: 10px;
    max-height: 569px;
    overflow-y: scroll;
}

.search-result-row {
    border-bottom: 1px solid #828B92;
    padding: 10px;
    color: #152028;
}

.search-result-row:hover {
    background-color: #47555E;
    cursor: pointer;
    color: white;
}

.exercise-definition-metrics-container {

}

.card {
    display: inline-block;
    padding: 10px;
    margin: 10px;
    background-color: white;
    box-shadow: 4px 3px #c1b6b6;
}

.exercise-personal-best-container {
    min-width: 200px;
    min-height: 100px;
    float: left;
    margin-left: 53px;
}

.exercise-metric-card-title {
    font-size: 20px;
    color: #152028;
}

.exercise-count-container {
    min-width: 200px;
    min-height: 100px;
    float: left;
}

.lbs-label {
    font-size: 12px;
    color: grey;
}

.personal-best-metric {
    font-size: 35px;
}

.exercise-definition-metrics-container-placeholder {
    background-color: #80808036;
    height: 400px;
    width: 750px;
    color: darkgrey;
    padding: 100px;
    margin: 25px;
    display: inline-block;
}

.metric-container-header {
    font-size: 30px;
    font-weight: bold;
    text-align: left;
    color: #152028;
}