.exercise-definition-validation-error-container {
    background-color: rgb(252, 214, 214);
    color: red;
    display: flex;
    align-items: center;
    border: 1px solid red;
}

.error-icon {
    margin-left: 5px;
}

.error-text-container {
    padding: 10px 0px;
    margin: 5px;
}

.definition-builder-footer-container {
    display: inline-flex;
    align-items: center;
}

.exercise-definition-builder-loading-spinner-container {
    display: inline-block;
    float: right;
}