.summary-widget-container {
    display: inline-block;
    width: 24%;
    
}

.widget-title {
    font-size: 20px;
}

.widget-title-container {
    text-align: left;
    border-bottom: 1px solid black;
    border-bottom: 1px solid white;
}

.widget-value {
    font-weight: bolder;
    font-size: 30px;
}

.widget {
    background: linear-gradient(to bottom,  rgb(154, 170, 184) 0%, rgb(154, 170, 184) 100%);
    margin: 5px 0 10px 10px;
    padding: 5px;
    border-radius: 5px;
    color: white;
}