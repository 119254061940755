.workout-builder-container {
    background: linear-gradient(to bottom, rgb(207, 221, 231) 0%, rgb(207, 221, 231) 100%);
    position: absolute;
    width: 1000px;
    top: 20%;
    left: 17%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.72);
}

.workout-builder-container:focus {
    outline:none;
}

.workout-draft-container {
    display: inline-block;
}

.exercise-search-container {
    display: inline-block;
    float: left;
    border-right: 1px solid #152028;
    min-height: 400px; 
}

.search-input-container {
    display: flex;
    align-items: center;
}

.search-input {
    padding: 10px;
    margin: 10px 10px 0px 10px;
    outline: none;
    width: 200px;
    display: block;
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    border: none;
}

.search-results-container {
    margin: 0px 10px;
    display: inline-block;
    float: left;
    border-bottom: 1px solid #152028;
    border-left: 1px solid #152028;
    border-right: 1px solid #152028;
    background-color: white;
    min-width: 150px;
}

.search-bar-row-renderer {
    padding-left: 5px;
}

.search-bar-row-renderer:hover {
    background-color: #152028;
    color:white;
    cursor: pointer;
}

.workout-name-input {
    padding: 10px;
    margin: 10px 10px 0px 10px;
    outline: none;
    width: 400px;
    font-size: 25px;
    display: inline-block;
    background-color: white;
    border-radius: 4px;
    border: none;
    color: #152028;
}

.model-rows-container {
    padding: 10px;
    display: inline-block;
    padding-right: 20px;
    border-right: 1px dashed grey;
    min-height: 300px;
}

.model-row {
    display: inline-block;
    min-width: 175px;
    background-color: white;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

.delete-icon {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer;
}

.muscle-overview-container {
    display: inline-block;
    float: right;
    padding-right: 65px;
}

.model-definition-container {
    padding-top: 10px;
}

.no-workouts-selected-shadow {
    margin: 10px;
    background-color: grey;
    opacity: 0.3;
    text-align: center;
    height: 200px;
}

.no-workouts-text {
    padding: 90px 0px 90px 0px;
}

.muscle-overview-title {
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
}

.workout-definition-builder-processing-text-container {
    display: inline-block;
}

.workout-definition-builder-header {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 25px;
    background-color: #47555E;
    color: white;
}

.exercise-definition-builder-title {
    display: inline-flex;
    align-items: center;
}

.add-exercise-definition-icon {
    display: inline-flex;
}
