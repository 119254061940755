.widget-container {
    display: inline-block;
    text-align: left;
    padding: 10px;
    margin: 10px 20px;
    min-width: 400px;
    background-color: white;
    box-shadow: 4px 3px #c1b6b6;
}

.definition-name-container {
    padding: 0;
    margin: 0;
}

.definition-name {
    display: inline-block;
    font-size: 25px;
    font-weight: bold;
    color: black;
    padding-right: 5px;
}

.appears-in-container {
    display: inline-block;
    color: grey;
    font-size: 14px;
}

.instance-count-container {

}

.max-weight {
    font-size: 14px;
    font-weight: bold;
}

.max-weight-label {
    display: inline-block;
    font-size: 10px;
    padding-right: 5px;
}

.metric-labels-container {
    display: inline-block;
    float: left;
    padding-right: 5px;
}

.metric-value {
    display: inline-block;
    width: 40%;
    padding-top: 7px;
    font-size: 20px;
    font-weight: bolder;
}

@media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 667px)
    and (orientation : portrait) {
        .widget-container {
            min-width: 300px;
            max-width: 300px;
        }
    }