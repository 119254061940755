.workout-instance-builder-container {
    background: linear-gradient(to bottom, rgb(207, 221, 231) 0%, rgb(207, 221, 231) 100%);
    position: absolute;
    width: 800px;
    min-height: 300px;
    top: 20%;
    left: 22%;
    border: none;
    border-radius: 10px;
    box-shadow: 0 0 18px rgba(0, 0, 0, 0.72);
}

.workout-instance-builder-container:focus {
    outline:none;
}

.search-workouts-input-container {
    text-align: center;
    display:inline-block;
}

.search-workouts-input {
    padding: 10px;
    margin: 10px 10px 0px 10px;
    outline: none;
    width: 200px;
    display: block;
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    border: none;
}

.search-results-dropdown-container{
    display: inline-block;
    float: left;
    margin-left: 10px;
    border-bottom: 1px solid #152028;
    border-left: 1px solid #152028;
    border-right: 1px solid #152028;
    background-color: white;
}

.workout-definition-search-result-container {
    padding: 10px;
    border-bottom: 1px solid grey;
}

.workout-definition-search-result-container:hover {
    background-color: #152028;
    color: white;
    cursor: pointer;
}

.hovered-workout-definition-container {
    display: inline-block;
    float: left;
}

.workout-instance-form-container {
    display: inline-block;
    float: right;
    padding: 10px;
}

.no-workout-definition-selected-container {
    margin: 10px;
    background-color: grey;
    opacity: 0.3;
    text-align: center;
    height: 200px;
    border-radius: 5px;
}

.no-workout-selected-text {
    padding: 90px 25px 90px 25px;
}

.weight-input-container {
    display: inline-block;
    padding-left: 5px;
}

.workout-instance-builder-input {
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    border: none;
    max-width: 60px;
}

.workout-instance-builder-header-input {
    font-size: 15px;
    background-color: white;
    border-radius: 4px;
    border: none;
}

.weight-input-units {
    padding-left: 5px;
    font-size: 12px;
}

.save-button-container {
    padding-top: 5px;
}

.header-input-container {
    padding-bottom: 5px;
}

.header-input-label {
    padding-bottom: 2px;
}

.workout-instance-builder-header {
    padding-left: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 25px;
    background-color: #47555E;
    color: white;
}

@media only screen 
    and (min-device-width : 320px) 
    and (max-device-width : 667px)
    and (orientation : portrait) {
        .workout-instance-builder-container {
            width: 450px;
            min-height: 300px;
        }

        .workout-instance-form-container {
            display: inline-block;
            padding: 10px;
            float: left;
        }

        .search-workouts-input-container {
            text-align: center;
            display: block;
        }
    }